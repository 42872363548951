<template>
  <section class="presentation-assistance">
    <div class="presentation-assistance__container">
      <h2 class="presentation-assistance__title text-title">Presentation Assistance</h2>
      <p class="presentation-assistance__description text-description">
        With an image of your space in hand, browngrotta arts will digitally “install” works that
        interest you or that we think will work on your wall, on your surface, in your room or
        outdoor location. We'll “install” them to scale and with shadow, if appropriate, to give you
        or your clients a realistic idea of just what you can expect when the piece is finally
        installed. We’ll send you our suggestions by e-mail as a high resolution digital pdf or by
        mail as a hardcopy printout. In either case, we'll include information about the artist and
        details about the piece. We can send you installation instructions and even arrange to be
        available by phone during installation, if you wish. Our aim: For the work to look good in
        the digital placement and even better IRL.
      </p>
      <BaseButton
        class="presentation-assistance__button button--white"
        text="INQUIRE"
        @click="$router.push('/art-advisory/presentation-assistance/inquire')"
      ></BaseButton>
    </div>
    <div class="carousel-wrapper">
      <IconButton
        class="carousel__button carousel__button--back button--circle button--white"
        @click="goToSlide('prev')"
      >
        <IconArrowTop class="carousel__button-icon carousel__button-icon"></IconArrowTop>
      </IconButton>
      <VueSlickCarousel v-bind="carouselSettings" ref="carousel" @afterChange="changeSlide">
        <div v-for="(slide, index) in slides" :key="index" class="slide">
          <img class="slide__image" :src="slide.image" :alt="slide.text" />
        </div>
      </VueSlickCarousel>
      <IconButton
        class="carousel__button carousel__button--next button--circle button--white"
        @click="goToSlide('next')"
      >
        <IconArrowTop class="carousel__button-icon carousel__button-icon"></IconArrowTop>
      </IconButton>
    </div>
    <p class="presentation-assistance__slide-description text-description">
      {{ slideActive.text }}
    </p>
  </section>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { mapMutations } from "vuex";

export default {
  components: {
    BaseButton,
    IconButton,
    IconArrowTop,
    VueSlickCarousel,
  },
  data() {
    return {
      carouselSettings: {
        centerMode: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        dots: false,
        infinite: true,
        centerPadding: "20px",
        variableWidth: true,
      },
      slides: [
        {
          image: require("@/assets/images/services/presentationAssistance/slide_1.jpg"),
          text: "We create portfolios for individual artists or artworks for designers’ convenience.",
        },
        {
          image: require("@/assets/images/services/presentationAssistance/slide_2.jpg"),
          text: "Artworks include templates to streamline installation.",
        },
        {
          image: require("@/assets/images/services/presentationAssistance/slide_3.jpg"),
          text: "On request, browngrotta co-curators will compile engaging works organized by size, price, color, etc.",
        },
        {
          image: require("@/assets/images/services/presentationAssistance/slide_4.jpg"),
          text: "Artwork suggestions can be made as early as the architectural drawing stage.",
        },
      ],
      slideActive: {},
    };
  },
  head: {
    title: function () {
      return {
        inner: "Presentation Assistance browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: `With an image of your space in hand, browngrotta arts will digitally “install” works that
        interest you or that we think will work on your wall, on your surface, in your room or
        outdoor location. We'll “install” them to scale and with shadow, if appropriate, to give you
        or your clients a realistic idea of just what you can expect when the piece is finally
        installed. We’ll send you our suggestions by e-mail as a high resolution digital pdf or by
        mail as a hardcopy printout. In either case, we'll include information about the artist and
        details about the piece. We can send you installation instructions and even arrange to be
        available by phone during installation, if you wish. Our aim: For the work to look good in
        the digital placement and even better IRL.`,
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Presentation Assistance browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Presentation Assistance browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Presentation Assistance browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: `With an image of your space in hand, browngrotta arts will digitally “install” works that
        interest you or that we think will work on your wall, on your surface, in your room or
        outdoor location. We'll “install” them to scale and with shadow, if appropriate, to give you
        or your clients a realistic idea of just what you can expect when the piece is finally
        installed. We’ll send you our suggestions by e-mail as a high resolution digital pdf or by
        mail as a hardcopy printout. In either case, we'll include information about the artist and
        details about the piece. We can send you installation instructions and even arrange to be
        available by phone during installation, if you wish. Our aim: For the work to look good in
        the digital placement and even better IRL.`,
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Presentation Assistance browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: `With an image of your space in hand, browngrotta arts will digitally “install” works that
        interest you or that we think will work on your wall, on your surface, in your room or
        outdoor location. We'll “install” them to scale and with shadow, if appropriate, to give you
        or your clients a realistic idea of just what you can expect when the piece is finally
        installed. We’ll send you our suggestions by e-mail as a high resolution digital pdf or by
        mail as a hardcopy printout. In either case, we'll include information about the artist and
        details about the piece. We can send you installation instructions and even arrange to be
        available by phone during installation, if you wish. Our aim: For the work to look good in
        the digital placement and even better IRL.`,
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Presentation Assistance browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  created() {
    this.slideActive = this.slides[0];
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Art Advisory",
        to: "/art-advisory",
        clickable: true,
      },
      {
        title: "Presentation Assistance",
        to: "/art-advisory/presentation-assistance",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    goToSlide(moveDirection) {
      let carousel = this.$refs.carousel;
      switch (moveDirection) {
        case "prev": {
          carousel.prev();
          break;
        }
        case "next": {
          carousel.next();
          break;
        }
      }
    },
    changeSlide(slideIndex) {
      this.slideActive = this.slides[slideIndex];
    },
  },
};
</script>

<style lang="scss" scoped>
.presentation-assistance {
  padding: 23rem 0;
  @media screen and (max-width: $xs) {
    padding: 24rem 0 23rem;
  }
  &__container {
    padding: 0 31.5rem 10.5rem;
    @media screen and (max-width: $xs) {
      padding: 0 25px 12.5rem;
    }
  }
  &__title {
    font-size: 4rem;
    margin-bottom: 10px;
  }
  &__description {
    font-weight: 100;
    margin-bottom: 2.5rem;
  }
  &__button {
    min-width: 18rem;
  }
  &__slide-description {
    font-weight: 100;
    margin: 0 auto;
    max-width: 60rem;
    text-align: center;
    @media screen and (max-width: $xs) {
      padding: 0 25px;
    }
  }
}

.carousel {
  &-wrapper {
    position: relative;
    margin-bottom: 10px;
  }
  &__button {
    width: 7rem;
    height: 7rem;
    @media screen and (max-width: $xs) {
      width: 4.2rem;
      height: 4.2rem;
    }
    &--next {
      position: absolute;
      right: 10.5rem;
      top: 50%;
      transform: rotate(90deg) translateY(-50%);
      z-index: 1;
    }
    &--back {
      position: absolute;
      left: 10.5rem;
      top: 50%;
      transform: rotate(-90deg) translateY(-50%);
      z-index: 1;
    }
    & &-icon {
      width: 18px;
      height: 12px;
      @media screen and (max-width: $xs) {
        width: 14px;
        height: 8px;
      }
    }
  }
}
.slide {
  margin: 0 5px;
  max-width: 60rem;
  max-height: 60rem;
  @media screen and (max-width: $xs) {
    max-width: 34.5rem;
    max-height: 34.5rem;
  }
  @media screen and (max-width: $xxxs) {
    max-width: 32.5rem;
    max-height: 32.5rem;
  }
  @media screen and (max-width: $xxxxs) {
    max-width: 27rem;
    max-height: 27rem;
  }
}
</style>
