import { render, staticRenderFns } from "./PresentationAssistance.vue?vue&type=template&id=2c6ced49&scoped=true&"
import script from "./PresentationAssistance.vue?vue&type=script&lang=js&"
export * from "./PresentationAssistance.vue?vue&type=script&lang=js&"
import style0 from "./PresentationAssistance.vue?vue&type=style&index=0&id=2c6ced49&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c6ced49",
  null
  
)

export default component.exports